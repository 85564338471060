import { Injectable, Inject, HostBinding } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { DOCUMENT } from "@angular/common";
import { Observable, Subject } from "rxjs";
import { Title } from "@angular/platform-browser";
@Injectable({
  providedIn: "root",
})
export class InitialService {
  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private titleService: Title
  ) { }

  appBaseUrl = "https://";

  api = this.appBaseUrl + "commonapi.xeat.lk/v2/admin/greatSetting";
  projectSettings: any;
  greatSettings: any;
  appSettings = new Subject();

  async load() {
    let fullUrl = window.location.href;

    this.api = `${this.appBaseUrl}commonapi.xeat.lk/v2/admin/greatSetting`
    // For Dynamic Projects
    // this.api = fullUrl.includes("localhost")
    //   ? `${this.appBaseUrl}commonapi.xeat.lk/v2/admin/greatSetting`
    //   : `${this.appBaseUrl}commonapi.xeat.lk/v2/admin/greatSetting?isFromWeb=true`;

    await this.getGreatSettings();
    this.subscribeToAppSettings();
  }

  subscribeToAppSettings() {
    this.appSettings.subscribe((res) => {
      if (res) {
        this.projectSettings = res;
        this.setData();
      }
    });
  }

  async getGreatSettings() {
    try {
      let resp = await this.http.get(this.api).toPromise();
      if (
        resp["response"]["success"] &&
        resp["data"].isActive &&
        (resp["data"].isDriverApplicationEnabled ||
          resp["data"]["paidFeatures"]["isDriverApplicationEnabled"])
      ) {
        this.greatSettings = resp["data"];
        // Only Use Modules which are active
        this.greatSettings.appType = this.greatSettings.appType.filter(
          (appType) => appType.status == true
        );
        // Set On localstorage for global access
        localStorage.setItem(
          "greatSettings",
          JSON.stringify(this.greatSettings)
        );
        localStorage.setItem("appId", this.greatSettings["appId"]);
        // Set project Base Url
        // this.appBaseUrl = this.greatSettings.baseURL + "/v1";
        this.loadAppSpecificSettings();
        return;
      } else {
        alert(`Failure From Great Admin !- ${resp["message"]}`);
      }
    } catch (err) {
      alert(`Failure From Great Admin #- ${JSON.stringify(err)}`);
    }
  }

  async loadAppSpecificSettings() {
    this.http
      .get(this.appBaseUrl + "commonapi.xeat.lk/v2/admin/getAppSetting")
      .subscribe((resp) => {
        if (resp["response"]["success"]) {
          this.projectSettings = resp["data"];
          this.setData();
        }
      });
  }

  setData() {
    // Set On localstorage for global access
    localStorage.setItem("appSettings", JSON.stringify(this.projectSettings));
    // Set Global Color
    if (this.projectSettings && this.projectSettings.colorCodes) {
      document.documentElement.style.setProperty(
        "--primary-color",
        this.projectSettings.colorCodes
      );
    }
    // Set Favicon
    if (this.projectSettings && this.projectSettings.favIcon) {
      this._document
        .getElementById("appFavicon")
        .setAttribute("href", this.projectSettings.favIcon);
    }
    // Set Title
    if (this.projectSettings && this.projectSettings.appName) {
      this.titleService.setTitle(
        this.projectSettings.appName + " - Driver Panel"
      );
    }
  }

  public getBaseUrl() {
    if (this.greatSettings) {
      const baseUrl = this.appBaseUrl;
      return {
        baseUrl: baseUrl,
        imageUrl: this.appBaseUrl,
      };

    }
  }
}
