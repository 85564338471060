import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appNumberDotOnly]",
})
export class NumberDotOnlyDirective {
  constructor(private _el: ElementRef) {}
  @HostListener("input", ["$event"]) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    // this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    // this._el.nativeElement.value = initalValue.replace(/[^0-9\.]/g, "");
    this._el.nativeElement.value = initalValue.replace(
      "^[a-zA-Z0-9,]*.?[a-zA-Z0-9,]*$"
    );
    // ( )
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
