import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { CommonService } from "../../../../core/services/common.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { ActivatedRoute } from "@angular/router";
import { HttpService } from "src/app/core/services/http.service";
import { coreUrlList } from "src/app/core/urlList/core.urlList";

@Component({
  selector: "app-add-vehicle-modal",
  templateUrl: "./add-vehicle-modal.component.html",
  styleUrls: ["./add-vehicle-modal.component.scss"],
})
export class AddVehicleModalComponent implements OnInit {
  vehicleForm: FormGroup;
  vehicleImage: any;
  submitted: boolean = false;
  errorImage: boolean = true;
  item: any;

  constructor(
    private dialog: MatDialogRef<AddVehicleModalComponent>,
    private formBuilder: FormBuilder,
    public comm: CommonService,
    public toastr: ToastrManager,
    public route: ActivatedRoute,
    public api: HttpService,
    public coreUrlList: coreUrlList
  ) {}

  ngOnInit() {
    this.vehicleForm = this.formBuilder.group({
      name: new FormControl("", [
        Validators.required,
        Validators.maxLength(35),
      ]),
      seats: new FormControl("10", [Validators.required, Validators.min(1)]),
    });
    if (this.item) {
      this.setValues();
    }
  }

  setValues = () => {
    if (this.item) {
      this.vehicleForm.patchValue({
        name: this.item.name,
      });
      if (this.item.image) {
        this.errorImage = false;
        this.vehicleImage = this.item.image;
      }
    }
  };

  async uploadImage(file) {
    let formData = new FormData();
    formData.append("file", file);
    let res = await this.api
      .post(this.coreUrlList.uploadFile, formData)
      .toPromise();
    return res["data"];
  }

  async fileHandler(event) {
    const File = event.target.files[0];
    if (
      File.type == "image/png" ||
      File.type == "image/jpg" ||
      File.type == "image/jpeg"
    ) {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event: any) => {
          this.vehicleImage = event.target.result;
        };
      }
      this.errorImage = false;
      this.vehicleImage = await this.uploadImage(File);
    } else {
      this.errorImage = true;
      this.toastr.errorToastr("Only PGN, JPEG ,JPG are Allow");
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.vehicleForm.valid && !this.errorImage) {
      var data = {
        name: this.vehicleForm.controls["name"].value.trim(),
      };
      data["verticalType"] = 1;
      data["image"] = this.vehicleImage;
      this.api.post(this.coreUrlList.vehicalTypes, data).subscribe((res) => {
        if (res["response"]["success"]) {
          this.comm.successToast(res["response"]["message"]);
          this.dialog.close("yes");
        } else {
          this.dialog.close("no");
        }
      });
    }
  }

  close() {
    this.dialog.close();
  }

  onUpdate() {
    this.submitted = true;
    if (this.vehicleForm.valid && this.submitted && !this.errorImage) {
      var data = {
        name: this.vehicleForm.controls["name"].value.trim(),
        updateId: this.item._id,
      };
      data["verticalType"] = 1;
      data["image"] = this.vehicleImage;
      this.api.put(this.coreUrlList.vehicalTypes, data).subscribe((res) => {
        if (res["response"]["success"]) {
          this.comm.successToast(res["response"]["message"]);
          this.dialog.close("yes");
        } else {
          this.dialog.close("no");
        }
      });
    }
  }
}
