<h2 mat-dialog-title>Pay History</h2>
<mat-dialog-content>
  <div class="table-wrap card">
    <div class="admin_table card-body">
      <div class="table-responsive tblDriverDetail">
        <table class="table display table-bordered product-overview mb-30">
          <thead>
            <tr>
              <th>#</th>
              <th>Pay Amount</th>
              <th>Pay Date</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let item of historyList | paginate: config1;
                let i = index
              "
            >
              <td>{{ (currentPage1 - 1) * 10 + i + 1 }}</td>
              <td>{{ appSettings.currency }} {{ item?.payAmount }}</td>
              <td>{{ item?.createdAt | date }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4
        style="padding: 20px;"
        *ngIf="historyList.length == 0"
        class="text-center clr-blk"
      >
        No records found.
      </h4>
    </div>
  </div>
</mat-dialog-content>
<div class="col-12 btm-btn">
  <div class="botton_right_buttons">
    <mat-dialog-actions>
      <button class="btn btn-danger common-btn" (click)="close()">Close</button>
    </mat-dialog-actions>
  </div>
</div>
