import { Component, createPlatform, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ToastrManager } from "ng6-toastr-notifications";

import { HttpService } from "src/app/core/services/http.service";
import { coreUrlList } from "src/app/core/urlList/core.urlList";
import { CommonService } from "src/app/core/services/common.service";

@Component({
  selector: "app-features-detail-modal",
  templateUrl: "./features-detail-modal.component.html",
  styleUrls: ["./features-detail-modal.component.scss"],
})
export class FeaturesDetailModalComponent implements OnInit {
  // isSubmitted: boolean = false;

  feature = [
    {
      title: "stripe",
      paymentMethod: 1,
      keys: [
        {
          keyTitle: "SECRET_KEY",
          placeholder: "Enter the Secret_key",
          required: true,
        },
        {
          keyTitle: "PUBLIC_KEY",
          placeholder: "Enter the public_key",
          required: true,
        },
      ],
    },
    {
      title: "RazorPay",
      paymentMethod: 2,
      keys: [
        {
          keyTitle: "KEY_ID",
          placeholder: "Enter the key_id",
          required: true,
        },
        {
          keyTitle: "KEY_SECRET",
          placeholder: "Enter the key_secret",
          required: true,
        },
      ],
    },
    {
      title: "GreenPay",
      paymentMethod: 3,
      keys: [
        {
          keyTitle: "GREEN_SECRET_KEY",
          placeholder: "Enter the Green_secret_key",
          required: true,
        },
        {
          keyTitle: "TERMINAL_ID",
          placeholder: "Enter the Terminal_id",
          required: true,
        },
        {
          keyTitle: "MERCHANT_ID",
          placeholder: "Enter the Merchant_id",
          required: true,
        },
        {
          keyTitle: "MERCHANT_ENDPOINT",
          placeholder: "Enter the Merchant_Endpoint",
          required: true,
        },
      ],
    },
    {
      title: "Dibsy",
      paymentMethod: 4,
      keys: [
        {
          keyTitle: "DIBSY_SECRET_KEY",
          placeholder: "Enter the Dibsy_secret_key",
          required: true,
        },
        {
          keyTitle: "URL",
          placeholder: "Enter the Url",
          required: true,
        },
      ],
    },
    {
      title: "Flex Pay",
      paymentMethod: 5,
      keys: [
        {
          keyTitle: "FLEX_PAY_SECRET_KEY",
          placeholder: "Enter the Flex pay secret key",
          required: true,
        },
        {
          keyTitle: "FLEX_MERCHANT",
          placeholder: "Enter the Flex pay merchant key",
          required: true,
        },
      ],
    },
    {
      title: "HyperPay",
      paymentMethod: 8,
      keys: [
        {
          keyTitle: "HYPER_PAY_ENTITY_ID",
          placeholder: "Enter the Hyper Pay Entity id",
          required: true,
        },
        {
          keyTitle: "HYPER_PAY_TOKEN",
          placeholder: "Enter the Hyper Pay Token key",
          required: true,
        },
      ],
    },
    {
      title: "DPO",
      paymentMethod: 9,
      keys: [
        {
          keyTitle: "DPO_COMPANY_TOKEN",
          placeholder: "Enter the Company Token",
          required: true,
        },
      ],
    },
    {
      title: "My Fatoorah",
      paymentMethod: 10,
      keys: [
        {
          keyTitle: "MYFATOORAH_TOKEN",
          placeholder: "Enter the Fatoorah token",
          required: true,
        },
      ],
    },
    {
      title: "Twilo",
      smsMethod: 1,
      keys: [
        {
          keyTitle: "TWILO_SENDER_NUMBER",
          placeholder: "Enter the Twilio_sender_number",
          required: true,
        },
        {
          keyTitle: "TWILO_ACCOUNT_SID",
          placeholder: "Enter the Twilio_account_number",
          required: true,
        },
        {
          keyTitle: "TWILO_AUTH_TOKEN",
          placeholder: "Enter the Twilio_auth_token",
          required: true,
        },
      ],
    },
    {
      title: "Sinch",
      smsMethod: 2,
      keys: [
        {
          keyTitle: "SINCH_FROM_NUMBER",
          placeholder: "Enter the Sinch number",
          required: true,
        },
        {
          keyTitle: "SINCH_REST_API_URL",
          placeholder: "Enter the api_Url",
          required: true,
        },
        {
          keyTitle: "SINCH_API_TOKEN",
          placeholder: "Enter the api_token",
          required: true,
        },
      ],
    },
    {
      title: "Sms Global",
      smsMethod: 3,
      keys: [
        {
          keyTitle: "SMS_GLOBAL_KEY",
          placeholder: "Enter the Sms_global_key",
          required: true,
        },
        {
          keyTitle: "SMS_GLOBAL_SECRET",
          placeholder: "Enter the sms_secret",
          required: true,
        },
      ],
    },
    {
      title: "Two Factor",
      smsMethod: 4,
      keys: [
        {
          keyTitle: "TWO_FACTOR_API_KEY",
          placeholder: "Enter the api_key",
          required: true,
        },
      ],
    },
    {
      title: "Smart SMS",
      smsMethod: 5,
      keys: [
        {
          keyTitle: "SMART_SMS_USER_NAME",
          placeholder: "Enter the user_name",
          required: true,
        },
        {
          keyTitle: "SMART_SMS_PASSWORD",
          placeholder: "Enter the sms_password",
          required: true,
        },
        {
          keyTitle: "SMART_SMS_SENDER_ID",
          placeholder: "Enter the sender Id",
          required: true,
        },
        {
          keyTitle: "SMART_SMS_URL",
          placeholder: "Enter the sms_url",
          required: true,
        },
      ],
    },
    {
      title: "SMS Bazar",
      smsMethod: 6,
      keys: [
        {
          keyTitle: "SMS_BAZAR_USER_NAME",
          placeholder: "Enter the user_name",
          required: true,
        },
        {
          keyTitle: "SMS_BAZAR_PASSWORD",
          placeholder: "Enter the password",
          required: true,
        },
        {
          keyTitle: "SMS_BAZAR_DLT_TEMPLATE",
          placeholder: "Enter the dlt_template",
          required: true,
        },
        {
          keyTitle: "SMS_BAZAR_FROM",
          placeholder: "Enter the sms_bazar_form",
          required: true,
        },
        {
          keyTitle: "SMS_BAZAR_PEID",
          placeholder: "Enter the sms_bazar_peid",
          required: true,
        },
      ],
    },
    {
      title: "SMS Country",
      smsMethod: 7,
      keys: [
        {
          keyTitle: "SMS_COUNTRY_USERNAME",
          placeholder: "Enter the user_name",
          required: true,
        },
        {
          keyTitle: "SMS_COUNTRY_PASSWORD",
          placeholder: "Enter the password",
          required: true,
        },
        {
          keyTitle: "SMS_COUNTRY_SENDERID",
          placeholder: "Enter the sender_id",
          required: true,
        },
      ],
    },
    {
      title: "SMS To SMS",
      smsMethod: 8,
      keys: [
        {
          keyTitle: "SMSTO_TOKEN",
          placeholder: "Enter the token",
          required: true,
        },
      ],
    },
    {
      title: "Fcm Server Key",
      thirdPartyMethod: 1,
      keys: [
        {
          keyTitle: "FCMSERVERKEY",
          placeholder: "Enter the FCM Server Key",
          required: true,
        },
      ],
    },
    {
      title: "S3 Url",
      thirdPartyMethod: 2,
      keys: [
        {
          keyTitle: "S3URL",
          placeholder: "Enter the S3 URL",
          required: true,
        },
      ],
    },
    {
      title: "Bucket Name",
      thirdPartyMethod: 3,
      keys: [
        {
          keyTitle: "BUCKET_NAME",
          placeholder: "Enter the Bucket Name",
          required: true,
        },
      ],
    },
    {
      title: "Access Key S3",
      thirdPartyMethod: 4,
      keys: [
        {
          keyTitle: "ACCESSKEYS3",
          placeholder: "Enter the S3 Access Key",
          required: true,
        },
      ],
    },
    {
      title: "Secret Access Key",
      thirdPartyMethod: 5,
      keys: [
        {
          keyTitle: "SECRETACCESSKEY",
          placeholder: "Enter the Secret Access Key",
          required: true,
        },
      ],
    },
    {
      title: "Grid From Email",
      thirdPartyMethod: 6,
      keys: [
        {
          keyTitle: "SENDGRIDFROMEMAIL",
          placeholder: "Enter the Grid from Email",
          required: true,
        },
      ],
    },
    {
      title: "Grid Key",
      thirdPartyMethod: 7,
      keys: [
        {
          keyTitle: "SENDGRIDKEY",
          placeholder: "Enter the Grid from Email",
          required: true,
        },
      ],
    },
    {
      title: "Token Api Key",
      thirdPartyMethod: 8,
      keys: [
        {
          keyTitle: "tokenApiKey",
          placeholder: "Enter the Token Api",
          required: true,
        },
      ],
    },
  ];
  activeFeature;
  activeStatus;
  paymentMethod;
  smsMethod;
  thirdPartyMethod;
  mainForm: FormGroup;
  submitted2: boolean = false;
  paymentId: any;

  constructor(
    private dialog: MatDialogRef<FeaturesDetailModalComponent>,
    private fb: FormBuilder,
    private api: HttpService,
    private comm: CommonService,
    private coreUrlList: coreUrlList
  ) {}

  ngOnInit() {
    console.log(this.paymentMethod, "PymentMethod");
    console.log(this.smsMethod, "smsMethod");
    console.log(this.activeFeature, "ActiveFeature");
    console.log(this.activeStatus, "ngonit");
    this.getPaymentStatus();
    this.getSmsStatus();
    this.createForm();
  }
  createForm() {
    let form = {};
    this.feature.filter((item) => {
      if (item.title == this.activeFeature) {
        item.keys.filter((data) => {
          form[data.keyTitle] = new FormControl("", [
            data.required ? Validators.required : undefined,
          ]);
        });
      }
    });
    this.mainForm = this.fb.group(form);
    console.log(form);
  }

  close() {
    var data = {
      response: "no",
    };
    this.dialog.close(data);
  }

  getPaymentStatus() {
    if (this.paymentMethod != undefined) {
      let params = `paymentMethod=${this.paymentMethod}`;
      this.api
        .getWithQuery(this.coreUrlList.getPaymentMethods, params)
        .subscribe((res) => {
          console.log(res, "ewrwerwer");
          if (res["response"]["success"]) {
            this.setvalues(res);
          }
        });
    }
  }
  getSmsStatus() {
    if (this.smsMethod != undefined) {
      let params = `smsMethod=${this.smsMethod}`;
      this.api
        .getWithQuery(this.coreUrlList.getSmsMethods, params)
        .subscribe((res) => {
          if (res["response"]["success"]) {
            this.setvalues(res);
          }
        });
    }
  }

  setvalues(res) {
    console.log(res, "Response Setvalue");

    console.log(this.mainForm, "reuiuierueir");
    if (res) {
      let formFeature = {};
      this.feature.filter((item) => {
        item.keys.filter((data) => {
          formFeature[data.keyTitle] = res.data[data.keyTitle];
        });
      });
      this.mainForm.patchValue(formFeature);
      console.log(formFeature, "FormFeature");
    }
  }
  onSubmit() {
    this.submitted2 = true;
    let data;
    this.feature.filter((item) => {
      if (
        item.paymentMethod == this.paymentMethod &&
        item.paymentMethod != undefined
      ) {
        data = this.mainForm.value;
        data.paymentMethod = this.paymentMethod;
      } else if (
        item.smsMethod == this.smsMethod &&
        item.smsMethod != undefined
      ) {
        data = this.mainForm.value;
        data.smsMethod = this.smsMethod;
      }
    });
    data.isActive = this.activeStatus;
    console.log(data, "Mainform value");
    if (data && this.paymentMethod != undefined) {
      this.api
        .post(this.coreUrlList.managePaymentMethods, data)
        .subscribe((res) => {
          if (res["response"]["success"]) {
            this.comm.successToast(res["response"]["message"]);
            console.log(res, "RESPONSE");
            this.paymentId = res["data"]["_id"];
          }
        });
      this.dialog.close(data);
    } else if (data && this.smsMethod != undefined) {
      this.api
        .post(this.coreUrlList.manageSmsMethods, data)
        .subscribe((res) => {
          console.log(res, "smsREsoonse");
          if (res["response"]["success"]) {
            this.comm.successToast(res["response"]["message"]);
            this.paymentId = res["data"]["_id"];
          }
        });
      this.dialog.close(data);
    }
  }
}
