import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
// import { SwUpdate } from "@angular/service-worker";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  // public updates: SwUpdate
  constructor(
    public myRoute: Router // public updates: SwUpdate
  ) {
    // console.log(updates, "gfyfgygly");
    // navigator.serviceWorker.register("/ngsw-worker.js");
    // updates.available.subscribe((event) => {
    //   console.log("current version: ", event.current);
    //   console.log("available version: ", event.available);
    //   localStorage.clear();
    //   document.location.reload();
    // });
  }

  ngOnInit() {
    // this.updates.available.subscribe((_) =>
    //   this.updates.activateUpdate().then(() => {
    //     document.location.reload();
    //   })
    // );
    var pointerX = -1;
    var pointerY = -1;
    document.onmousemove = function (event) {
      pointerX = event.pageX;
      pointerY = event.pageY;
    };
    setInterval(pointerCheck, 60000);
    function pointerCheck() {
      localStorage.setItem("coordinates", JSON.stringify(pointerX));
    }
    setInterval(onSessionCheck, 170000);
    function onSessionCheck() {
      if (pointerX == JSON.parse(localStorage.getItem("coordinates"))) {
        if (this.comm) {
          this.comm.logout();
        }
      }
    }
  }
}
