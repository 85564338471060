<h2 mat-dialog-title>Forgot Password</h2>
<mat-dialog-content>
  <form>
    <div class="form-group">
      <input
        type="email"
        class="form-control form-control-user"
        name="email"
        id="exampleInputEmail"
        [formControl]="email"
        aria-describedby="emailHelp"
        placeholder="Enter Email Address"
        required
      />
    </div>
  </form>
  <div *ngIf="isSubmitted && email.invalid" class="error-msg">
    <div *ngIf="email.errors.required">Email is required</div>
    <div *ngIf="email.errors.pattern">Email is not valid</div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    class="btn btn-primary common-btn"
    type="submit"
    (click)="onSubmit()"
  >
    Submit
  </button>
  <button class="btn btn-danger common-btn" (click)="close()">Cancel</button>
</mat-dialog-actions>
