import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { ToastrManager } from "ng6-toastr-notifications";
import { CommonService } from "src/app/core/services/common.service";

import { HttpService } from "src/app/core/services/http.service";
import { coreUrlList } from "src/app/core/urlList/core.urlList";
@Component({
  selector: "app-pay-modal-driver",
  templateUrl: "./pay-modal-driver.component.html",
  styleUrls: ["./pay-modal-driver.component.scss"],
})
export class PayModalDriverComponent implements OnInit {
  categoryForm: FormGroup;
  submitted: boolean = false;
  item: any;
  greatSettings: any;
  verticalType: any;

  constructor(
    private dialog: MatDialogRef<PayModalDriverComponent>,
    private formBuilder: FormBuilder,
    public comm: CommonService,
    public toastr: ToastrManager,
    public api: HttpService,
    public coreUrlList: coreUrlList
  ) { }

  ngOnInit() {
    this.greatSettings = this.comm.getGreatSetting();
    this.getVerticalType();
    this.categoryForm = this.formBuilder.group({
      amount: new FormControl("", [Validators.required]),
    });
  }

  getVerticalType() {
    this.greatSettings.appType.filter((item) => {
      console.log("item", item);
      this.verticalType = this.comm.getVerticalTypeByModuleKey(item.moduleKey);
    });
    console.log("this.verticalType", this.verticalType);
  }

  onSubmit() {
    this.submitted = true;
    if (this.categoryForm.valid) {
      let data = {
        id: [this.item._id],
        amount: this.categoryForm.controls.amount.value,
        type: "driver",
        verticalType: this.verticalType,
      };
      this.api.post(this.coreUrlList.payStore, data).subscribe((res) => {
        if (res["response"]["success"]) {
          this.comm.successToast("Paid Successfully.");
          this.dialog.close("yes");
        } else {
          this.dialog.close("no");
        }
      });
    }
  }

  close() {
    this.dialog.close("yes");
  }
}
