const driver = "4003/v2";
export class orderUrls {
  // Store Type
  storeType: string = `${driver}/admin/store/storeType`;

  // // Category
  category: string = `${driver}/admin/store/category`;
  // Driver
  driverOrder: string = `${driver}/admin/driver/getDriverAllOrders`;

  // Store
  getMerchantCategories: string = "/admin/store/getcategories";
  getMerchantsFromType: string = `${driver}/admin/store/getStoresByType`;
  storeAllOrders: string = `${driver}/admin/store/orders/all`;
  // Order Manual Assignment
  getAllNearDrivers: string = `${driver}/admin/driver/getAllNearDrivers`;
  assignDriverManually: string = `${driver}/admin/store/manuallyAssignOrder`;

  // // Order List
  getAllOrdersCSV: string = `${driver}/admin/getAllOrdersCSV`;

  getUserAllOrders: string = `${driver}/admin/user/getUserAllOrders`;

  getPendingOrderCount: string = `${driver}/admin/pendingOrderCount`;
}
