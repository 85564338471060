import { Component, OnInit } from "@angular/core";
import { CommonService } from "../../../../core/services/common.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { MatDialogRef } from "@angular/material/dialog";
import { HttpService } from "src/app/core/services/http.service";
import { coreUrlList } from "src/app/core/urlList/core.urlList";
@Component({
  selector: "app-document-modal",
  templateUrl: "./document-modal.component.html",
  styleUrls: ["./document-modal.component.scss"],
})
export class DocumentModalComponent implements OnInit {
  categoryImage: any;
  File;
  submitted: boolean = false;
  item: any;
  errorImage: boolean;
  expiryDate;
  today;
  constructor(
    private dialog: MatDialogRef<DocumentModalComponent>,
    public comm: CommonService,
    public api: HttpService,
    public coreUrlList: coreUrlList,
    public toastr: ToastrManager
  ) {}

  ngOnInit() {
    this.today = new Date().toISOString().split("T")[0];
    if (this.item) {
      this.setValues();
    }
  }

  async fileHandler(event) {
    this.File = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        this.categoryImage = event.target.result;
      };
    }
    if (
      this.File.type == "image/png" ||
      this.File.type == "image/jpg" ||
      this.File.type == "image/jpeg"
    ) {
      this.errorImage = false;
      this.File = event.target.files[0];
    } else {
      this.errorImage = true;
      this.toastr.errorToastr("Only PNG, JPEG ,JPG are Allow ");
    }
  }

  setValues = () => {
    if (this.item) {
      if (this.item.expiryDate) {
        this.expiryDate = this.item.expiryDate;
      }
      if (this.item.image) {
        if (this.item.image.charAt(0) == "/") {
          this.categoryImage = this.comm.imageUrl + this.item.image;
        } else {
          this.categoryImage = this.item.image;
        }
      }
    }
  };

  close() {
    this.dialog.close();
  }

  onUpdate() {
    this.submitted = true;
    if ((this.File && !this.errorImage) || this.expiryDate) {
      if (this.File || this.expiryDate) {
        let formData = new FormData();
        if (this.expiryDate) {
          const data = {
            expiryDate: this.expiryDate,
          };
          formData.append("data", JSON.stringify(data));
        }
        if (this.File) {
          formData.append("image", this.File);
        }
      }
    } else {
      this.dialog.close("no");
    }
  }

  dateChange(event) {
    this.expiryDate = event.target.value;
  }
}
