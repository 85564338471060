const taxi = "";
export class taxiUrlList {
  // Bookings
  booking: string = "/shuttle/booking/admin";
  cancelBookings: string = "/shuttle/booking/admin/cancel";
  getShuttleBookingDetail: string = "/shuttle/booking/admin";

  // Location
  addShuttleLocation: string = "/shuttle/location/add";
  updateShuttleLocation: string = "/shuttle/location/update";
  getAllShuttleLocation: string = "/shuttle/location/getAll";
  deleteShuttleLocation: string = "/shuttle/location/delete";

  getAllShuttleOverview: string = "/shuttle/booking/seatstats";
  getAllShuttleLocationAdmin: string = "/shuttle/location/getAll/admin";
  getShuttleRouteById: string = "/shuttle/route/getById";

  addShuttleRoute: string = "/shuttle/route/add";
  updateShuttleRoute: string = "/shuttle/route/update";

  getVendersList: string = "/shuttle/vender/getall";

  getAllShuttleRoute: string = "/shuttle/route/getAll";
  deleteShuttleRoute: string = "/shuttle/route/delete";

  getAllShuttleDriver: string = "/taxi/v2/admin/driver/all/shuttle";
  getAllFreeRoute: string = "/shuttle/route/shuttlefree";

  addShuttle: string = "/shuttle/add";
  updateShuttle: string = "/shuttle/update";
  getShuttleById: string = "/shuttle/getById";

  getAllShuttle: string = "/shuttle/getAll";
  deleteShuttle: string = "/shuttle/delete";

  updateShuttleStatus: string = "/shuttle/shuttleStatusUpdate";

  // Vendor
  getVenderById: string = "/shuttle/vender/get";
  addVender: string = "/shuttle/vender/add";
  updateVender: string = "/shuttle/vender/update";
  getAllVenders: string = "/shuttle/vender/get";
  deleteVender: string = "/shuttle/vender/delete";
  updateVenderStatus: string = "/shuttle/vender/vendorStatusUpdate";

  // Taxi Booking
  getTaxiBooking: string = "/admin/taxi/bookings";
  addVehicle: string = "/admin/taxi/bookings";
  getTaxiBookingById: string = "/admin/taxi/booking";
}
