import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  APP_INITIALIZER,
} from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import "hammerjs";
import { MaterialModule } from "./modules/material/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DatepickerModule, BsDatepickerModule } from "ngx-bootstrap/datepicker";
// import {
//   ServiceWorkerModule,
//   SwPush,
//   SwRegistrationOptions,
//   SwUpdate,
// } from "@angular/service-worker";
import { NgxSpinnerModule } from "ngx-spinner";
import { DatePipe, AsyncPipe } from "@angular/common";
import { NgxPaginationModule } from "ngx-pagination";
import { RouterModule } from "@angular/router";
import { ToastrModule } from "ng6-toastr-notifications";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AgmCoreModule } from "@agm/core";
import { AgmDirectionModule } from "agm-direction";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { coreUrlList } from "./core/urlList/core.urlList";
import { CoreModule } from "./core/core.module";
import { AuthGuard } from "./core/guards/auth.guard";
import { GetInterceptorService } from "./core/interceptors/get-interceptor.service";
import { SetInterceptorService } from "./core/interceptors/set-interceptor.service";
import { InitialService } from "./core/services/initial.service";
import { DocumentModalComponent } from "./modules/main/driver/document-modal/document-modal.component";
import { AddVehicleModalComponent } from "./modules/main/vehicle-type/add-vehicle-modal/add-vehicle-modal.component";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { PayModalDriverComponent } from "./modules/main/payments/pay-modal-driver/pay-modal-driver.component";
import { FeaturesDetailModalComponent } from "./modules/main/admin-settings/manage-features/features-detail-modal/features-detail-modal.component";
import { PaymentHistoryModalComponent } from "./modules/main/payments/payment-history-modal/payment-history-modal.component";
import { orderUrls } from "./modules/main/Order-Modules/services/order-urls";
import { ForgotPasswordModalComponent } from "./modules/auth/forgot-password/forgot-password-modal.component";
import { environment } from "src/environments/environment";
import { taxiUrlList } from "./modules/main/Taxi-Modules/services/taxi-urls";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";

export function startupServiceFactory(
  InitialService: InitialService
): Function {
  return () => InitialService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    DocumentModalComponent,
    AddVehicleModalComponent,
    PayModalDriverComponent,
    PaymentHistoryModalComponent,
  ],
  imports: [
    RouterModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CoreModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AgmDirectionModule,
    GooglePlaceModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyA91V8Cmkc4EJQY2Ggv_QE5yCQcZl6EBpw",
      libraries: ["geometry", "places", "drawing"],
    }),
    NgMultiSelectDropDownModule.forRoot(),
    // ServiceWorkerModule.register("ngsw-worker.js", {
    //   enabled: true,
    //   registrationStrategy: "registerImmediately",
    // }),
    NgxIntlTelInputModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    // ServiceWorkerModule.register("/ngsw-worker.js", {
    //   enabled: environment.production,
    // }),
  ],
  providers: [
    coreUrlList,
    taxiUrlList,
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [InitialService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GetInterceptorService,
      multi: true,
    },
    Title,
    AsyncPipe,
    AuthGuard,
    DatePipe,
    orderUrls,
    // {
    //   provide: SwRegistrationOptions,
    //   useFactory: () => ({ enabled: true }),
    // },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" },
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ForgotPasswordModalComponent,
    DocumentModalComponent,
    AddVehicleModalComponent,
    PayModalDriverComponent,
    FeaturesDetailModalComponent,
    PaymentHistoryModalComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {
  constructor() { }
  // constructor(update: SwUpdate, push: SwPush) {
  //   update.available.subscribe((update) => {});
  // }
}
