import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-payment-history-modal",
  templateUrl: "./payment-history-modal.component.html",
  styleUrls: ["./payment-history-modal.component.scss"],
})
export class PaymentHistoryModalComponent implements OnInit {
  historyList;
  appSettings;
  currentPage1: number = 1;
  config1: any = {
    id: "page",
    currentPage: 1,
    itemsPerPage: 10,
  };

  constructor(private dialog: MatDialogRef<PaymentHistoryModalComponent>) {}

  ngOnInit() {
    this.appSettings = JSON.parse(localStorage.getItem("appSettings"));
  }

  close() {
    this.dialog.close("yes");
  }
}
