import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  ActivatedRoute,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "src/app/modules/auth/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  path: string;
  constructor(
    private _location: Location,
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    // console.log("routes");
    console.log(activatedRoute.snapshot.url); // array of states
    // console.log(this.activatedRoute.snapshot.firstChild.url[0].path);
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (state.url.includes("/auth/adminLogin")) {
      var arr = state.url.split("/");
      console.log(arr[arr.length - 1]);
      localStorage.setItem("driverToken", arr[arr.length - 1]);
      localStorage.setItem("role", JSON.stringify("Admin"));
      this.router.navigate(["/dashboard"]);
    } else {
      return true;
    }
    if (state.url.includes("/auth/login")) {
      if (
        localStorage.driverToken == null ||
        localStorage.driverToken == undefined ||
        localStorage.driverToken == ""
      ) {
        console.log("2");
        this.router.navigate(["/auth/login"]);
      } else {
        console.log("3");
        this.router.navigate(["/dashboard"]);
        return false;
      }
    }
    //  else if(state.url.includes("dashboard")) {
    //   this._location.back()

    // }
    else {
      return true;
    }
    // console.log("4");

    // if (state.url.includes("/auth/login")) {
    //   if (
    //     localStorage.token == null ||
    //     localStorage.token == undefined ||
    //     localStorage.token == ""
    //   ) {
    //     return true;
    //   } else {
    //     this.router.navigate(["/dashboard"]);
    //     return false;
    //   }
    // } else {
    //   if (
    //     localStorage.token == null ||
    //     localStorage.token == undefined ||
    //     localStorage.token == ""
    //   ) {
    //     this.router.navigate(["/auth/login"]);
    //   } else {
    //     return true;
    //   }
    // }
  }
}
