<h2 mat-dialog-title *ngIf="item">Edit Vehicle Type</h2>
<h2 mat-dialog-title *ngIf="!item">Add Vehicle Type</h2>
<mat-dialog-content>
  <form [formGroup]="vehicleForm">
    <div class="usr_profile_inrmain">
      <div class="row">
        <div class="form-group col-md-12">
          <div class="usr_profile_biodata">
            <label>Name</label>
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                name="name"
                formControlName="name"
                appNoSpaceAtFirst
              />
            </div>
            <div
              *ngIf="submitted && vehicleForm.controls.name.errors"
              class="invalid-feedback"
            >
              <div *ngIf="vehicleForm.controls.name.errors.required">
                Name is required.
              </div>
              <div *ngIf="vehicleForm.controls.name.errors.maxlength">
                Name can be max 35 characters long.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <div class="outer_uploadsshdf bigholderhor default_viewer">
            <p class="heading-setting-form">Image</p>

            <div class="image_placeholder">
              <figure>
                <img [src]="vehicleImage | imageFilter" alt="" />
              </figure>
            </div>

            <div class="image_placeholder_showup_file">
              <div class="dftlp">
                <img src="assets/img/img-dummy.svg" alt="choose_img" />
                <span>Upload File</span>
              </div>
              <input
                type="file"
                accept="image/*"
                (change)="fileHandler($event)"
              />
            </div>

            <div *ngIf="submitted && errorImage" class="invalid-feedback">
              Image is required.
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<div class="botton_right_buttons">
  <mat-dialog-actions>
    <button
      class="btn btn-primary mr-2 common-btn"
      *ngIf="!item"
      (click)="onSubmit()"
    >
      Add
    </button>
    <button
      class="btn btn-primary mr-2 common-btn"
      *ngIf="item"
      (click)="onUpdate()"
    >
      Update
    </button>
    <button class="btn btn-danger common-btn" (click)="close()">Close</button>
  </mat-dialog-actions>
</div>
