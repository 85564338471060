import { Directive, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appRequired]',
  // providers: [new Provider(NG_VALIDATORS, { useExisting: forwardRef(() => MyRequiredValidator), multi: true })]

})
export class RequiredDirective {
  @Input('myRequired') required: boolean;
  constructor() { }
  validate(control: AbstractControl): { [key: string]: any } {
    return this.required && !control.value
      ? { myRequired: true }
      : null;
  }

}
