<h2 mat-dialog-title>Payment</h2>
<mat-dialog-content>
  <form [formGroup]="mainForm" *ngFor="let item of feature">
    <div
      class="form-group"
      *ngIf="
        item.paymentMethod == paymentMethod &&
        item.smsMethod == smsMethod &&
        item.thirdPartyMethod == thirdPartyMethod
      "
    >
      <label>{{ item.title }}</label>
      <div *ngFor="let data of item.keys">
        <div class="label_fdx">
          <label for="">{{ data.keyTitle }}</label>
        </div>
        <input
          type="text"
          class="form-control form-control-user"
          id="exampleInputEmail"
          formControlName="{{ data.keyTitle }}"
          placeholder="{{ data.placeholder }}"
        />
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn btn-primary common-btn" type="submit" (click)="onSubmit()">
    Submit
  </button>
  <button class="btn btn-danger common-btn" (click)="close()">Cancel</button>
</mat-dialog-actions>
