import { Pipe, PipeTransform } from "@angular/core";
import { CommonService } from "../services/common.service";

@Pipe({
  name: "imageFilter",
})
export class ImgaeFilterPipe implements PipeTransform {
  constructor(public comm: CommonService) {}
  imageUrl = this.comm.imageUrl;
  transform(value: any): any {
    if (value && value.charAt(0) == "/") {
      return this.imageUrl + value;
    } else {
      return value;
    }
  }
}
